import React from 'react';
import { Route } from 'react-router-dom';
import {
  discoveryApiRef,
  useApi,
  oktaAuthApiRef,
} from '@backstage/core-plugin-api';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable,
  CatalogTableColumnsFunc,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {
  CostInsightsLabelDataflowInstructionsPage,
  CostInsightsPage,
  CostInsightsProjectGrowthInstructionsPage,
} from '@backstage-community/plugin-cost-insights';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { GraphiQLPage } from '@backstage-community/plugin-graphiql';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import {
  PlaylistIndexPage,
  PlaylistPage,
} from '@backstage-community/plugin-playlist';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { TextSize } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { HomePage } from './components/home/HomePage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import AlarmIcon from '@material-ui/icons/Alarm';
import { FeatureExplorerPage } from '@schibsted/backstage-plugin-feature-react';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import * as plugins from './plugins';
import qs from 'qs';

// Parses supplied JWT token and returns the payload
function parseJwt(token: string): { exp: number } {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
}

// Returns milliseconds until the supplied JWT token expires
function msUntilExpiry(token: string): number {
  const payload = parseJwt(token);
  const remaining =
    new Date(payload.exp * 1000).getTime() - new Date().getTime();
  return remaining;
}

// Calls the specified url regularly using an auth token to set a token cookie
// to authorize regular HTTP requests when loading techdocs
async function setTokenCookie(url: string, token: string) {
  await fetch(url, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  // Call this function again a few minutes before the token expires
  const ms = msUntilExpiry(token) - 4 * 60 * 1000;
  setTimeout(
    () => {
      setTokenCookie(url, token);
    },
    ms > 0 ? ms : 10000,
  );
}

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  icons: {
    // Custom icon example
    alert: AlarmIcon,
  },
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          providers={[
            {
              id: 'okta-auth-provider',
              title: 'Okta',
              message: 'Simple Backstage Application Login',
              apiRef: oktaAuthApiRef,
            },
          ]}
          align="center"
          onSignInSuccess={async identityApi => {
            // When logged in, set a token cookie
            const token = await identityApi.getCredentials();
            if (typeof token === 'string') {
              setTokenCookie(await discoveryApi.getBaseUrl('cookie'), token);
            }
            // Forward results
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const myColumnsFunc: CatalogTableColumnsFunc = entityListContext => {
  const params = qs.parse(global.window.location.search, {
    ignoreQueryPrefix: true,
  }).columns;
  if (
    typeof params === 'object' &&
    !Array.isArray(params) &&
    Array.isArray(params.field) &&
    Array.isArray(params.title)
  ) {
    const fields = params.field as string[];
    const titles = params.title as string[];
    const newColumns = fields.map((column, index) => {
      switch (column) {
        case 'resolved.name':
          return CatalogTable.columns.createNameColumn();
        case 'resolved.partOfSystemRelationTitle':
          return CatalogTable.columns.createSystemColumn();
        case 'resolved.ownedByRelationsTitle':
          return CatalogTable.columns.createOwnerColumn();
        case 'entity.metadata.description':
          return CatalogTable.columns.createMetadataDescriptionColumn();
        case 'entity.metadata.tags':
          return CatalogTable.columns.createTagsColumn();
        default:
          return {
            field: column,
            title: titles[index],
          };
      }
    });
    return newColumns;
  }

  return CatalogTable.defaultColumnsFunc(entityListContext);
};

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route
      path="/catalog"
      element={<CatalogIndexPage columns={myColumnsFunc} />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <TextSize />
        <Mermaid
          darkConfig={{ theme: 'dark' }}
          lightConfig={{ theme: 'forest' }}
        />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/explore" element={<ExplorePage />} />
    <Route
      path="/tech-radar/aftonbladet"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="aftonbladet"
          pageTitle="Aftonbladet"
          subtitle="What tech we use, would like to learn more about, or want to avoid"
        />
      }
    />
    <Route
      path="/tech-radar/da_ai"
      element={
        <TechRadarPage
          width={1500}
          height={800}
          id="da_ai"
          pageTitle="Data and AI"
          subtitle="What tech we use, would like to learn more about, or want to avoid"
        />
      }
    />
    <Route
      path="/tech-radar/spp"
      element={
        <TechRadarPage
          width={1700}
          height={1000}
          id="spp"
          pageTitle="SPP Tech Radar"
          subtitle="SPP Tech Radar"
        />
      }
    />
    <Route path="/graphiql" element={<GraphiQLPage />} />
    <Route path="/features" element={<FeatureExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/cost-insights" element={<CostInsightsPage />} />
    <Route
      path="/cost-insights/investigating-growth"
      element={<CostInsightsProjectGrowthInstructionsPage />}
    />
    <Route
      path="/cost-insights/labeling-jobs"
      element={<CostInsightsLabelDataflowInstructionsPage />}
    />
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/playlist" element={<PlaylistIndexPage />} />
    <Route path="/playlist/:playlistId" element={<PlaylistPage />} />
    <Route path="/devtools" element={<DevToolsPage />}>
      {customDevToolsPage}
    </Route>
    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
