export const sheets: Record<string, { latest: string; previous?: string }> = {
  aftonbladet: {
    latest:
      'https://docs.google.com/spreadsheets/d/1bo3JCoFkXsNR-D_EcIdxPREwNIqCA3vgrOWLaB6Yu34/gviz/tq?tqx=out:csv&sheet=Latest',
    previous:
      'https://docs.google.com/spreadsheets/d/1bo3JCoFkXsNR-D_EcIdxPREwNIqCA3vgrOWLaB6Yu34/gviz/tq?tqx=out:csv&sheet=Previous',
  },
  da_ai: {
    latest:
      'https://docs.google.com/spreadsheets/d/1KTASruQq9fWIfNjnjA6hXYVCvn0m6JSO74U0r7tzHoM/gviz/tq?tqx=out:csv&sheet=Latest',
    previous:
      'https://docs.google.com/spreadsheets/d/1KTASruQq9fWIfNjnjA6hXYVCvn0m6JSO74U0r7tzHoM/gviz/tq?tqx=out:csv&sheet=Previous',
  },
  spp: {
    latest:
      'https://docs.google.com/spreadsheets/d/1UEKE62GdRwRy0zIcSAy-ygTxwC5Qm1KDsyKKVHUq5-k/gviz/tq?tqx=out:csv&sheet=tech-radar-data',
  },
};
