import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';
import { sheets } from './config';
import { addMoveState, transform } from './transformer';

export const ringIds = ['adopt', 'trial', 'assess', 'hold'] as const;

export class GoogleSheetsTechRadarClient implements TechRadarApi {
  async load(id?: string): Promise<TechRadarLoaderResponse> {
    const sheetUrls = id ? sheets[id] : undefined;
    if (!sheetUrls) {
      throw new Error(
        `🚧 No data sources defined for radar id ${id}. Add one in config.ts`,
      );
    }
    const [latest, previous] = await Promise.all([
      getData(sheetUrls.latest),
      sheetUrls.previous ? getData(sheetUrls.previous) : undefined,
    ]);

    if (previous) {
      latest.entries.forEach(addMoveState(previous));
    }

    return latest;
  }
}

const getData = async (sheetUrl: string): Promise<TechRadarLoaderResponse> => {
  const sheetsData = await fetch(sheetUrl);
  if (sheetsData.status === 200) {
    const text = await sheetsData.text();
    return transform(text);
  } else if (sheetsData.status === 404) {
    throw new Error(
      `🔥 Spreadsheet  ${sheetUrl} does not exist. Check for typos`,
    );
  }
  throw new Error(
    `⚠️ Unknow error when fetching sheet: ${sheetsData.status} - ${sheetsData.statusText}`,
  );
};
